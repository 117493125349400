import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import moment from "moment";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";

import ContainerAnimation from "components/ContainerAnimation";
import { useAuth } from "providers/Auth";
import { useCompany } from "providers/Company";
import { useDarkMode } from "providers/DarkMode";
import { useUser } from "providers/User";
import { api } from "services";
import { RoleDescription } from "types/enums";

import CompanySelect from "./components/CompanySelect";
import ProfessionalSelect from "./components/ProfessionalSelect";
import TimeFrameSelect from "./components/TimeFrameSelect";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

enum TimeFrame {
  LastWeek,
  LastMonth,
  LastYear,
}

const placeholderChartData = [
  {
    label: "janeiro",
    quoteTotal: 0,
    value: 0,
  },
  {
    label: "fevereiro",
    quoteTotal: 0,
    value: 0,
  },
  {
    label: "março",
    quoteTotal: 0,
    value: 0,
  },
  {
    label: "abril",
    quoteTotal: 0,
    value: 0,
  },
  {
    label: "maio",
    quoteTotal: 0,
    value: 0,
  },
  {
    label: "junho",
    quoteTotal: 0,
    value: 0,
  },
  {
    label: "julho",
    quoteTotal: 0,
    value: 0,
  },
  {
    label: "agosto",
    quoteTotal: 0,
    value: 0,
  },
  {
    label: "setembro",
    quoteTotal: 0,
    value: 0,
  },
  {
    label: "outubro",
    quoteTotal: 0,
    value: 0,
  },
  {
    label: "novembro",
    quoteTotal: 0,
    value: 0,
  },
  {
    label: "dezembro",
    quoteTotal: 0,
    value: 0,
  },
];

export default function Dashboard() {
  const { userRole } = useAuth();
  const { userData } = useUser();
  const { darkMode } = useDarkMode();
  const {
    professionalCompanies,
    isLoadingProfessionalCompanies,
    getProfessionalCompanies,
  } = useCompany();

  useEffect(() => {
    if (
      userData.id &&
      (userRole === RoleDescription.Professional ||
        userRole === RoleDescription.Secretary)
    ) {
      getProfessionalCompanies(userData.id as number);
    }
  }, [userData]);

  const [indicators, setIndicators] = useState<{
    quoteApproved: number;
    quoteCancelled: number;
    quoteFinalized: number;
    quotePending: number;
    quoteWinning: number;
  }>({
    quoteApproved: 0,
    quoteCancelled: 0,
    quoteFinalized: 0,
    quotePending: 0,
    quoteWinning: 0,
  });

  const [companyId, setCompanyId] = useState<number>(-1);
  const [professionalId, setProfessionalId] = useState<number>(-1);

  const [timeFrame, setTimeFrame] = useState<{
    value: TimeFrame;
    label: string;
  }>({
    value: TimeFrame.LastWeek,
    label: "Última semana",
  });

  let timeFrameStartDay = moment();
  const timeFrameEndDay = moment();

  switch (timeFrame.value) {
    case TimeFrame.LastWeek:
      timeFrameStartDay = timeFrameStartDay.subtract(6, "days");
      break;
    case TimeFrame.LastMonth:
      timeFrameStartDay = timeFrameStartDay.subtract(30, "days");
      break;
    case TimeFrame.LastYear:
      timeFrameStartDay = timeFrameStartDay.subtract(365, "days");
      break;
    default:
      break;
  }

  const [averageTicketGeneral, setAverageTicketGeneral] = useState<
    {
      value: number;
      quoteTotal: number;
      label: string;
    }[]
  >(placeholderChartData);

  const [averageTicketApproved, setAverageTicketApproved] = useState<
    {
      value: number;
      quoteTotal: number;
      label: string;
    }[]
  >(placeholderChartData);

  const [selectedProfessional, setSelectedProfessional] = useState<{
    value: number;
    label: string;
  } | null>(null);

  const dashboardRequestsBody = {
    startDate: timeFrameStartDay,
    endDate: timeFrameEndDay,
    professionalId: professionalId === -1 ? undefined : professionalId,
    companyId: companyId === -1 ? undefined : companyId,
  };

  useEffect(() => {
    async function getIndicators() {
      try {
        const { data } = await api.post(
          "/Quote/Dashboards/Date",
          dashboardRequestsBody
        );

        setIndicators(data);
      } catch (error) {
        console.error(error);
      }
    }

    getIndicators();
  }, [companyId, professionalId, timeFrame]);

  useEffect(() => {
    async function getAverageTicketGeneral() {
      try {
        const { data } = await api.post(
          "/Quote/graphic/general",
          dashboardRequestsBody
        );

        setAverageTicketGeneral(data);
      } catch (error) {
        console.error(error);
      }
    }

    getAverageTicketGeneral();
  }, [companyId, professionalId, timeFrame]);

  const averageTicketGeneralValues = averageTicketGeneral.map(
    (value) => value.value
  );

  const amountTicketGeneralValues = averageTicketGeneral.map(
    (value) => value.quoteTotal
  );

  useEffect(() => {
    async function getAverageTicketApproved() {
      try {
        const { data } = await api.post(
          "/Quote/graphic/approved",
          dashboardRequestsBody
        );

        setAverageTicketApproved(data);
      } catch (error) {
        console.error(error);
      }
    }

    getAverageTicketApproved();
  }, [companyId, professionalId, timeFrame]);

  const averageTicketApprovedValues = averageTicketApproved.map(
    (value) => value.value
  );

  const amountTicketApprovedValues = averageTicketApproved.map(
    (value) => value.quoteTotal
  );

  const labels = averageTicketGeneral.map((value) => value.label);

  return (
    <>
      <ContainerAnimation className="content">
        <div className="bg-white">
          <div className="container">
            <div className="row justify-content-between align-items-center gx-2 pb-4">
              <div className="col-12 col-lg-auto pb-2">
                <h1 className="mb-0">Dashboard</h1>
              </div>
              <div className="col-12 col-lg-9">
                <div className="row justify-content-lg-end gx-2">
                  <div className="col-12 col-sm-6 col-md-4 pb-2">
                    <TimeFrameSelect
                      value={timeFrame}
                      options={[
                        {
                          value: TimeFrame.LastWeek,
                          label: "Última semana",
                        },
                        {
                          value: TimeFrame.LastMonth,
                          label: "Último mês",
                        },
                        {
                          value: TimeFrame.LastYear,
                          label: "Último ano",
                        },
                      ]}
                      defaultValue={{
                        value: TimeFrame.LastWeek,
                        label: "Última semana",
                      }}
                      setValue={setTimeFrame}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 pb-2">
                    <CompanySelect
                      isLoadingProfessionalCompanies={
                        isLoadingProfessionalCompanies
                      }
                      professionalCompanies={professionalCompanies}
                      setCompanyId={setCompanyId}
                      setProfessionalId={setProfessionalId}
                      setSelectedProfessional={setSelectedProfessional}
                    />
                  </div>
                  {userRole === RoleDescription.Secretary ||
                    userRole === RoleDescription.Admin ? (
                    <div className="col-12 col-md-4 pb-2">
                      <ProfessionalSelect
                        companyId={companyId}
                        setProfessionalId={setProfessionalId}
                        selectedProfessional={selectedProfessional}
                        setSelectedProfessional={setSelectedProfessional}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-flex flex-column gap-5 ">
          {userRole !== RoleDescription.Admin &&
            professionalCompanies.length === 0 && (
              <div className="alert alert-warning mb-0 text-center">
                <i className="ui uil-exclamation-triangle me-2"></i>
                <span>
                  Seu usuário ainda <b>não possui instituições cadastradas</b>.
                  Contacte o administador para que ele possa cadastrar e vincular
                  uma instituição ou acesse a página de{" "}
                  <Link to="/menus" className="fw-bold alert-link btn-link">
                    Menus
                  </Link>
                </span>
              </div>
            )}
          <div className="row g-3">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="card px-3 pb-3 pt-1 h-100 border-1 border-danger text-danger">
                <i className="uil uil-bill display-4 opacity-25 position-absolute top-0 end-0"></i>
                <span className="fw-bold display-4">
                  {indicators.quoteWinning}
                </span>
                <span>Orçamentos vencendo em 1 dia</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="card px-3 pb-3 pt-1 h-100 border-1 border-warning">
                <i className="uil uil-bill display-4 opacity-25 position-absolute top-0 end-0 text-warning"></i>
                <span className="fw-bold display-4">
                  {indicators.quotePending}
                </span>
                <span>Orçamentos pendentes</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="card px-3 pb-3 pt-1 h-100 border-1 border-dark">
                <i className="uil uil-bill display-4 opacity-25 position-absolute top-0 end-0"></i>
                <span className="fw-bold display-4">
                  {indicators.quoteApproved}
                </span>
                <span>Orçamentos aprovados</span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="card px-3 pb-3 pt-1 h-100 border-1 border-info">
                <i className="uil uil-bill display-4 opacity-25 position-absolute top-0 end-0 text-info"></i>
                <span className="fw-bold display-4">
                  {indicators.quoteFinalized}
                </span>
                <span>Orçamentos finalizados</span>
              </div>
            </div>
          </div>
          <div className="row gx-5">
            <div className="col-12 col-md-6">
              <Bar
                style={{
                  height: 500,
                }}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      ticks: {
                        color: !darkMode ? "#666666" : "#dbdbdb",
                      },
                      grid: {
                        color: !darkMode
                          ? "rgba(0,0,0,0.1)"
                          : "rgba(255,255,255,0.1)",
                      },
                    },
                    x: {
                      ticks: {
                        color: !darkMode ? "#666666" : "#dbdbdb",
                      },
                      grid: {
                        color: !darkMode
                          ? "rgba(0,0,0,0.1)"
                          : "rgba(255,255,255,0.1)",
                      },
                    },
                  },
                  responsive: true,
                  color: !darkMode ? "#2ed17c" : "#34e98b",
                  plugins: {
                    title: {
                      display: true,
                      text: "Ticket médio dos orçamentos no geral",
                      color: !darkMode ? "#000" : "#FFF",
                    },
                    tooltip: {
                      callbacks: {
                        footer: (tooltipItems: any) => {
                          return (
                            "Número de orçamentos: " +
                            amountTicketGeneralValues[tooltipItems[0].dataIndex]
                          );
                        },
                      },
                    },
                  },
                }}
                data={{
                  labels,
                  datasets: [
                    {
                      label: "Ticket médio",
                      data: averageTicketGeneralValues,
                      borderWidth: 1,
                      backgroundColor: !darkMode ? "#2ed17c" : "#34e98b",
                    },
                  ],
                }}
              />
            </div>
            <div className="col-12 col-md-6">
              <Bar
                style={{
                  height: 500,
                }}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    y: {
                      ticks: {
                        color: !darkMode ? "#666666" : "#dbdbdb",
                      },
                      grid: {
                        color: !darkMode
                          ? "rgba(0,0,0,0.1)"
                          : "rgba(255,255,255,0.1)",
                      },
                    },
                    x: {
                      ticks: {
                        color: !darkMode ? "#666666" : "#dbdbdb",
                      },
                      grid: {
                        color: !darkMode
                          ? "rgba(0,0,0,0.1)"
                          : "rgba(255,255,255,0.1)",
                      },
                    },
                  },
                  responsive: true,
                  color: !darkMode ? "#2ed17c" : "#34e98b",
                  plugins: {
                    title: {
                      display: true,
                      text: "Ticket médio dos orçamentos aprovados",
                      color: !darkMode ? "#000" : "#FFF",
                    },
                    tooltip: {
                      callbacks: {
                        footer: (tooltipItems: any) => {
                          return (
                            "Número de orçamentos: " +
                            amountTicketApprovedValues[tooltipItems[0].dataIndex]
                          );
                        },
                      },
                    },
                  },
                }}
                data={{
                  labels,
                  datasets: [
                    {
                      label: "Ticket médio",
                      data: averageTicketApprovedValues,
                      borderWidth: 1,
                      backgroundColor: !darkMode ? "#2ed17c" : "#34e98b",
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </ContainerAnimation>
    </>
  );
}