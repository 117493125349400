import { createTheme, ThemeProvider } from "@mui/material/styles";
import AOS from "aos";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useLocation } from 'react-router-dom';

import Button from "components/Button";
import CacheBuster from "components/CacheBuster";
import ScrollToTop from "components/ScrollToTop";
import { useAuth } from "providers/Auth";
import { useDarkMode } from "providers/DarkMode";
import Router from "routes";
import GlobalStyle from "styles/global";

import "aos/dist/aos.css";

const theme = createTheme({
  palette: {
    common: {
      white: '#FFF',
      black: '#000'
    },
    primary: {
      main: '#34e98b',
      light: '#26c070',
      contrastText: '#fff',
    },
    secondary: {
      main: '#34e98b',
      light: '#26c070',
      contrastText: '#fff',
    },
    background: {
      default: '#FFF',
      paper: '#f8f9fa',
    },
    text: {
      primary: '#666666',
      secondary: '#595f64',
      disabled: '#818181',
      // hint: '#9794a4',
    },
    error: {
      main: '#dc3545',
    },
    warning: {
      main: '#ffc107',
    },
    info: {
      main: '#17a2b8',
    },
    success: {
      main: '#28a745',
    },
    divider: 'rgba(0,0,0, 0.1)',
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontSize: 16,
  }
});
const darktheme = createTheme({
  palette: {
    common: {
      white: '#1a1c1a',
      black: '#fff'
    },
    primary: {
      main: '#34e98b'
    },
    secondary: {
      main: '#34e98b'
    },
    background: {
      default: '#1a1c1a',
      paper: '#242725',
    },
    text: {
      primary: '#dbdbdb',
      secondary: '#595f64',
      disabled: '#818181',
      // hint: '#9794a4',
    },
    error: {
      main: '#dc3545',
    },
    warning: {
      main: '#ffc107',
    },
    info: {
      main: '#17a2b8',
    },
    success: {
      main: '#28a745',
    },
    divider: 'rgba(255,255,255, 0.1)',
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontSize: 16,
  }
});

const App = () => {
  const location = useLocation();
  const { darkMode } = useDarkMode();
  const { token } = useAuth();
  useEffect(() => {
    AOS.init({});
  }, []);

  const isBeforeDateLimit = (): boolean => {
    // Define a data limite (6/03/2025)
    const dateLimit = new Date(2025, 2, 6); // O mês é indexado a partir de 0 (janeiro = 0)
    // Obtém a data atual
    const now = new Date();
    // Compara as datas
    return now < dateLimit;
  };

  const setCookieUntilEndOfDay = (name: string, value: string) => {
    const now = new Date();
    const endOfDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23, 59, 59
    );
    const expires = endOfDay.toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}; path=/`;
  };

  const getCookie = (name: string): string | null => {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  };

  const [notificationModal, setNotificationModal] = useState(token && token != "" && isBeforeDateLimit() ? getCookie("notificacao-carnaval") ? false : true : false);

  useEffect(() => {
    console.log('Página mudou para:', location.pathname);
      setNotificationModal(token && token != "" && isBeforeDateLimit() ? getCookie("notificacao-carnaval") ? false : true : false);
  }, [location]);

  return (
    <CacheBuster>
      <ThemeProvider theme={(!darkMode ? theme : darktheme)}>
        <GlobalStyle />
        <ScrollToTop />
        <Router />

        <Modal
          backdrop="static"
          centered
          show={notificationModal}
          onHide={() => {
            setNotificationModal(false);
          }}
          dialogClassName="modal-45w"
        >
          <Modal.Header closeButton={true} className="bg-primary">
            <Modal.Title className="h5 m-0 text-center w-100"><i className="uil uil-exclamation-triangle me-2"></i>ATENÇÃO!<i className="uil uil-exclamation-triangle ms-2"></i></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Devido ao feriado de Carnaval, <b>nos dias 03 e 04 de março</b>, as liquidações programadas para esses dias deverão ocorrer no próximo dia útil, 05 de março.</p>
            <p>Em caso de dúvidas, nossa equipe está à disposição para auxiliá-los.</p>
            <p>Atenciosamente,</p>
            <p>Equipe <b>Docttor<span className="text-primary">pay</span></b></p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              className="btn-link"
              onClick={() => {
                setCookieUntilEndOfDay("notificacao-carnaval", "false");
                setNotificationModal(false);
              }}
            >
              Não exibir mais hoje
            </Button>

            <Button
              type="button"
              className="btn-primary"
              onClick={() => { setNotificationModal(false); }}
            >
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </ThemeProvider>
    </CacheBuster>
  );
};

export default App;
